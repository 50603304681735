<template>
    <BackofficeBase :loader_prop="loader_prop">
      Delete User
    </BackofficeBase>
  </template>
  
  <script>
  import BackofficeBase from "./BackofficeBase";
  import BackendModel from "../../models/BackendModel";
  
  export default {
    name: 'AdminUserDelete',
    components: {
      BackofficeBase
    },
    data() {
      return {
        loader_prop: true
      }
    },
    async mounted() {
      let backendModel = new BackendModel()
      await backendModel.backendRequest("/Api/service/backoffice/delete_work_day_employee", {id : this.$route.query.id, working_date: this.$route.query.working_date})
      await this.$router.push({ path: '/backoffice/user_report' , query : {'id' : this.$route.query.id}})
    }
  }
  </script>
  
  <style>
  .item_action {
    font-size: 10px;
  }
  </style>
  